import React, { useRef, useState } from 'react';
import { useFullScreenModal } from '@app/components/modal-views/full-screen-modal-context';
import { ApiResponse, ContactUsDto, LocationAttributes } from '@app/models/dtos/strapi';
import { TextField } from '@mui/material';
import Button from '@app/components/ui/button';
import Close from '@app/components/icons/close';
import cn from 'classnames';
import RadialGradient from '@app/components/ui/radial-gradient';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/toastId';
import environments from '@app/configs/environments';
import { submitForm } from '@app/lib/api/contact-us';
import ReCAPTCHA from 'react-google-recaptcha';

interface IContactUs {
    locations?: Array<ApiResponse<LocationAttributes>>;
}

function ContactUs({ locations }: IContactUs) {
    const { closeModal } = useFullScreenModal();
    const [step, setStep] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const captchaRef = useRef<ReCAPTCHA>(null);
    const [formField, setFormField] = useState<ContactUsDto>({
        name: '',
        email: '',
        message: '',
        'g-recaptcha-response': ''
    });
    const [isSubmit, setSubmit] = useState(false);
    const errorMessage = 'Field is required';
    const steps = [
        {
            title: 'Contact Us',
            heading: 'We’d Love To Hear From You',
            description: 'Have questions, inquiries, or want to discuss a potential project? We’re here to help and would be delighted to hear from you.'
        },
        {
            title: 'Message Sent!',
            heading: 'We Will Get Back To You Soon.',
            description: 'We will make every effort to respond to your message promptly. While you wait, feel free to explore our website to learn more about our services we provide.'
        }
    ];

    const handleSendMessage = async (e: any) => {
        setSubmit(true);
        e.stopPropagation();
        e.preventDefault();
        await captchaRef.current?.executeAsync().then(async (response) => {
            const requestBody: ContactUsDto = {
                ...formField,
                'g-recaptcha-response': response
            };
            if (!!formField.name && !!formField.message && !!formField.email && step === 0) {
                setLoading(true);
                const isResponse = await submitForm(requestBody);
                if (isResponse) {
                    setStep(1);
                    setLoading(false);
                    toast('submitted!!', { toastId: ToastId.SUCCESS_TOAST, type: 'success' });
                } else {
                    setLoading(false);
                    toast('something went wrong', { toastId: ToastId.ERROR_TOAST, type: 'error' });
                }
            }
        });
    };
    const StepZero = () => (
        <>
            <div className="mt-10 flex flex-col gap-10 overflow-hidden">
                <TextField variant="standard" error={isSubmit && !formField.name} onChange={handleInput} id="name" placeholder="Your Name" helperText={isSubmit && !formField.name && errorMessage}></TextField>
                <TextField variant="standard" error={isSubmit && !formField.email} type="email" onChange={handleInput} id="email" placeholder="Your Email" helperText={isSubmit && !formField.email && errorMessage}></TextField>
                <TextField variant="standard" error={isSubmit && !formField.message} onChange={handleInput} id="message" multiline rows={5} placeholder="Your Message" helperText={isSubmit && !formField.message && errorMessage}></TextField>
                <ReCAPTCHA size="invisible" ref={captchaRef} sitekey={environments.RECAPTCHA_SITE_KEY} />
                <div className="flex justify-end">
                    <Button size="large" disabled={!formField.name || !formField.email || !formField.message} isLoading={isLoading}>
                        Send Message
                    </Button>
                </div>
            </div>
        </>
    );
    const StepOne = () => (
        <>
            <div className="mt-10">
                <Button onClick={() => closeModal()} size="large">
                    Go To Home
                </Button>
            </div>
        </>
    );
    const handleInput = (e: any) => {
        setFormField({
            ...formField,
            [e.target.id]: e.target.value
        });
    };

    return (
        <div className="flex !h-full w-full flex-col justify-end">
            <form onSubmit={handleSendMessage} className={cn('relative flex w-full flex-col-reverse  justify-center gap-[50px] bg-white px-5 py-[30px] sm:flex-row md:gap-[147px] xl:py-[60px]', step === 1 && 'md:min-h-[724px]')}>
                <Close onClick={closeModal} className="absolute right-10 top-10 h-5 w-5 cursor-pointer text-gray-600 hover:text-black sm:h-8 sm:w-8 md:h-10 md:w-10" />
                <RadialGradient className="-left-[100px] -top-[100px] xl:left-0" />
                {locations && (
                    <div id="locations" className="flex flex-col gap-[76px]">
                        {locations.map((location) => {
                            const { country, address, postalCode, phone, email, others } = location.attributes;
                            return (
                                <div key={location.id} className="P1 flex flex-col space-y-4 ">
                                    <p className="P2"> {country}</p>
                                    <div>
                                        <p>{address}</p>
                                        <p>{postalCode}</p>
                                    </div>
                                    <p>{phone}</p>
                                    {email && <p>{email}</p>}
                                    {others && others.length != 0 && others.map((other) => <p key={other.id}>{other.title}</p>)}
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className="flex w-full   flex-col  md:max-w-[666px]">
                    <p className="P2 text-black-1 ">{steps[step].title}</p>
                    <p className="h2-responsive mb-2 mt-4 text-black-1">{steps[step].heading}</p>
                    <p className="P2 text-black-2">{steps[step].description}</p>
                    {step === 0 && StepZero()}
                    {step === 1 && StepOne()}
                </div>
            </form>
        </div>
    );
}

export default ContactUs;
